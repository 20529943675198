import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { GatsbyLinkProps } from "gatsby-link";
import { StoreState, toggleSidebar } from "../../store";
import { MenuProps, MenuItem } from "../Menu";
import Button, { ButtonTypes } from "../Button/Button";
import { style, classes } from "./SidebarMenu.st.css";

interface SidebarMenuProps extends MenuProps {
  visible?: boolean;
  dispatch?: Dispatch<any>;
  Link: React.ComponentClass<GatsbyLinkProps<any>>;
}

export const SidebarMenu = ({ dispatch, items, pathname, Link, visible }: SidebarMenuProps) => {
  const isActive = (item: MenuItem) => (item.exact) ? pathname === item.path : pathname.startsWith(item.path);
  const activeItem = items.find((item: MenuItem) => isActive(item)) || {} as MenuItem;
  return (
    <div className={style(classes.root, { visible })}>
      <div className={style(classes.menu)}>
        {items.map((item) => {
          const active = isActive(item);
          return <Button
            className={style(classes.button)}
            type={ButtonTypes.GATSBY_LINK}
            href={item.path}
            active={active}
            Link={Link}
            key={item.path}
            label={item.name}
            onClick={() => dispatch && dispatch(toggleSidebar())}
          />;
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  visible: state.isSidebarVisible,
});

export default connect<any, void, SidebarMenuProps>(mapStateToProps)(SidebarMenu);
