import { Link } from "gatsby";
import * as React from "react";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import SiteFooter from "./SiteFooter/SiteFooter";
import { Provider } from "react-redux";
import { store } from "../store";
import { style, classes } from "./Layout.st.css";

export const menuItems = [
  { name: "שער", path: "/", exact: true, icon: "home", inverted: true },
  { name: "שירים טיפוסיים", path: "/typed-poems/", exact: true, icon: "info circle" },
  { name: "לורם איפסום", path: "/loremipsum/", exact: true, icon: "info circle" },
  // { name: "רשימות", path: "/notes/", exact: false, icon: "newspaper" },
];

export interface LayoutProps {
  location: {
    pathname: string;
  };
  children: any;
}

const Layout = (props: LayoutProps) => {
  const { pathname } = props.location;
  const isHome = pathname === "/";
  return (
    <Provider store={store}>
      <div className={style(classes.layout)}>
        <HeaderMenu
          className={style(classes.headerMenu)}
          Link={Link}
          pathname={pathname}
          items={menuItems}
          isHome={isHome}
        />
        <article className={style(classes.article)}>
          {props.children}
        </article>
        <SiteFooter isHome={isHome} />
      </div>
      <SidebarMenu Link={Link} pathname={pathname} items={menuItems} visible={false} />
    </Provider>
  );
};

export default Layout;

export const withLayout = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class WithLayout extends React.Component<P & LayoutProps> {
    render() {
      return (
        <Layout location={this.props.location}>
          <WrappedComponent {...this.props} />
        </Layout>
      );
    }
  };
