import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { StoreState, toggleSidebar } from "../../store";
import { MenuProps } from "../Menu";
import Button, { ButtonTypes } from "../Button/Button";
// import style from "react-syntax-highlighter/dist/styles/hljs/agate";
import { style, classes } from "./HeaderMenu.st.css";
// import { Link } from "gatsby";

interface HeaderMenuProps extends MenuProps {
  visible?: boolean;
  dispatch?: Dispatch<any>;
  className?: string;
  Link: any;
  isHome: boolean;
}

export const HeaderMenu = ({ Link, items, pathname, dispatch, visible, isHome, className }: HeaderMenuProps) =>
  <div className={style(classes.root, {}, className)}>
    <div className={style(classes.menu)}>
      <Button
        type={ButtonTypes.TRIGGER}
        className={style(classes.item, { visible, mobileOnly: true }, className)}
        onClick={() => dispatch && dispatch(toggleSidebar())}
        title={visible ? "Close" : "Open"}
      />
      <Button
        type={ButtonTypes.GATSBY_LINK}
        className={style(classes.headerMenuTitle, {}, className)}
        label={`תומר ליכטש`}
        disabled={isHome}
        href={isHome ? `#` : `/`}
        Link={isHome ? null : Link}
      />
      {items.map((item) => <Button
        type={ButtonTypes.GATSBY_LINK}
        className={style(classes.item, {
          active: (item.exact) ? pathname === item.path : pathname.startsWith(item.path),
          mobileHidden: true,
        }, className)}
        href={item.path}
        key={item.path}
        Link={Link}
        label={item.name}
      />)}
    </div>
  </div>;

const mapStateToProps = (state: StoreState) => ({
  visible: state.isSidebarVisible,
});

// export default connect()(HeaderMenu);
export default connect<any, void, HeaderMenuProps>(mapStateToProps)(HeaderMenu);
