import * as React from "react";
// import { connect } from "react-redux";
// import { StoreState } from "../../store";
import { style, classes } from "./Button.st.css";
import { Link } from "gatsby";

interface ButtonProps {
  type: ButtonTypes;
  disabled?: boolean;
  active?: boolean;
  label?: string;
  icon?: string;
  href?: string;
  target?: string;
  title?: string;
  className?: string;
  key?: string;
  name?: string;
  sublabel?: string;
  Link?: any;
  onClick?: () => void;
}

export enum ButtonTypes {
  HREF = "HREF",
  GATSBY_LINK = "GATSBY_LINK",
  TRIGGER = "TRIGGER",
  DISABLED = "DISABLED",
}

export const Button = (props: ButtonProps) => {
  const {
    type,
    label,
    icon,
    target,
    disabled,
    title,
    active,
    className,
    sublabel,
  } = props;

  const symbol = <img
    className={style(classes.symbol)}
    src={icon}
    alt={title}
    title={title}
    aria-label={title}
  />;

  if (!type) {
    return <span className={style(classes.root, { disabled, active }, className)}>{label}</span>;
  }

  switch (type) {
    case ButtonTypes.DISABLED: {
      return <button
        title={title}
        onClick={() => props.onClick && props.onClick()}
        className={style(classes.root, { disabled, active }, className)}>
        <span className={style(classes.label)}>{label}</span>
        <span className={style(classes.icon)}>
          <img className={style(classes.symbol)} src={icon} alt={title} title={title} aria-label={title} />
        </span>
      </button>;
    }
    case ButtonTypes.GATSBY_LINK: {
      const { href } = props;
      return <span onClick={() => props.onClick && props.onClick()}
        className={style(classes.root, { disabled, active }, className)}>
        <Link
          to={href}
          title={title}
          className={style(classes.href)}>
          <span className={style(classes.label)}>{label}</span>
          {icon ? <span className={style(classes.icon)}>{symbol}</span> : null}
        </Link>
      </span>;
    }
    case ButtonTypes.HREF: {
      const { href } = props;
      return <a
        href={href}
        target={target}
        title={title}
        onClick={() => props.onClick && props.onClick()}
        className={style(classes.root, { disabled, active }, className)}>
        <span className={style(classes.label)}>{label}</span>
        {icon ? <span className={style(classes.icon)}>{symbol}</span> : null}
      </a>;
    }
    case ButtonTypes.TRIGGER: {
      return <button
        title={title}
        onClick={() => props.onClick && props.onClick()}
        className={style(classes.root, { disabled, active }, className)}>
        <span className={style(classes.label)}>{label}</span>
        {sublabel ? <span className={style(classes.sublabel)}>{sublabel}</span> : null}
        {icon ? <span className={style(classes.icon)}>{symbol}</span> : null}
      </button>;
    }
    default: {
      return null;
    }
  }
};

// const mapStateToProps = (state: StoreState) => ({
// });

// export default connect<any, void, ButtonProps>(mapStateToProps)(Button);
export default Button;
