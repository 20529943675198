// import { Link } from "gatsby";
import * as React from "react";
import { style, classes } from "./SiteFooter.st.css";

export interface FooterProps {
  isHome: boolean;
}

const SiteFooter = (props: FooterProps) => {
  const { isHome } = props;
  const fullYear = new Date().getFullYear();
  return (
    <footer className={style(classes.root)} data-is-home={isHome}>
      <div className={style(classes.container, { align: "center" })}>
        <ul className={style(classes.iconList)}>
          <li className={style(classes.iconContainer)}>
            <a
              title="Facebook"
              target="_blank"
              href="https://facebook.com/tomerlichtash"
              className={style(classes.icon, { facebook: true })}>
            </a>
          </li>
          <li className={style(classes.iconContainer)}>
            <a
              title="Twitter"
              target="_blank"
              href="https://twitter.com/tomerlichtash"
              className={style(classes.icon, { twitter: true })}>
            </a>
          </li>
          <li className={style(classes.iconContainer)}>
            <a
              title="Instagram"
              target="_blank"
              href="https://instagram.com/tomerlichtash"
              className={style(classes.icon, { instagram: true })}>
            </a>
          </li>
          <li className={style(classes.iconContainer)}>
            <a
              title="LinkedIn"
              target="_blank"
              href="https://il.linkedin.com/in/tomerlichtash"
              className={style(classes.icon, { linkedin: true })}>
            </a>
          </li>
          <li className={style(classes.iconContainer)}>
            <a
              title="Github"
              target="_blank"
              href="https://github.com/tomerlichtash"
              className={style(classes.icon, { github: true })}>
            </a>
          </li>
        </ul>
        <p className={style(classes.siteSignature)}>
          <span className={style(classes.siteLicenseYear)}>{fullYear}</span>&nbsp;
              <span
                className={style(classes.siteLicenseLabel)}
                title={`${fullYear} (CC-BY-NC-SA) Tomer Lichtash`}>(
                  <a
                  href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
                  target="_blank"
                  title="Attribution-NonCommercial-ShareAlike 4.0 International (CC BY-NC-SA 4.0)">CC</a>)
                </span>&nbsp;
              <span className={style(classes.siteLicenseAuthor)}>Tomer Lichtash</span>
        </p>
      </div>
    </footer>
  );
};

export default SiteFooter;
